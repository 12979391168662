'use strict';

import request from '../request';
import fetch from '../request/fetch';
export default {
  // 开发环境登陆
  login(data) {
    return request.post('/account/login', data);
  },
  register(data) {
    return request.post('/register', data);
  },
  forgetPassword(data) {
    return request.get('/forget/password', data);
  },
  updatePassword(data) {
    return request.put('/update/password', data);
  },
  userAccount(data) {
    return request.put('/user/account', data);
  },
  imei(data) {
    return request.put('/user/imei', data);
  },
  feedback(data) {
    return request.post('/feedback', data);
  },
  saveUserInfo(data) {
    return request.post('/weixin/save/user', data);
  },
  placeOrder(data) {
    return request.post('/place', data);
  },
  cancellation() {
    return request.post('/cancellation');
  }
};
