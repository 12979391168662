/**
 * Created by GanQianZhao on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
const isExternal = (path) => {
    return /^(https?:|mailto:|tel:)/.test(path);
  };

  export const emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  const validUserName = (str) => {
    return str.trim() >= 0;
  };
  const PHONE = (val) => {
    return /^\d{7,12}$/.test(val);
  };
  const standerPhone = (rule, value, callback) => {
    // console.log('phone')
    var points = /^1[3456789]\d{9}$/;
    if (value !== '' && !points.test(value)) {
      callback(new Error('请输入正确的联系号码'));
    } else {
      callback();
    }
  };
  const EMAIL = (rule, value, callback) => {
    if (value !== '' && !emailReg.test(value)) {
      callback(new Error('请输入正确格式的邮箱'));
    } else {
      callback();
    }
  };

  const CUR_DATE_LIMIT = (rule, value, callback) => {
    if (typeof (value) === 'object' && (new Date()).valueOf() <= value.valueOf()) {
      callback(new Error('不能选将来时间'));
    } else {
      callback();
    }
  };

  const POINT_LIMIT = (rule, value, callback) => {
    var points = /^\d{1,7}(\.\d{1,2})?$/;
    if (value && value !== '' && !points.test(value)) {
      callback(new Error('整数小于7位且小数点不能超过两位'));
    } else {
      callback();
    }
  };

  const VALIDATE_ID_CARD = (rule, value, callback) => {
    var points = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/;
    if (value != null && value !== '' && !points.test(value)) {
      callback(new Error('身份证号码格式有误'));
    } else {
      callback();
    }
  };
  const CARGO_SECTION = (rule, value, callback, source) => {
    if (!source.c_name || rule.field !== rule.file) {
      callback();
      return;
    }

    if (value === '') {
      callback(new Error('该字段不能为空'));
    } else {
      callback();
    }
    return false;
  };
  export function isPositiveInteger (s) {
    var re = /^[0-9]+$/;
    return re.test(s);
  }
  const STRING_NUMBER = (rule, value, callback) => {
    const reg = new RegExp('^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$');
    // console.log(value)
    if (!value) {
      callback(new Error('请输入正确的金额!'));
    } else if (reg.test(value) === false) {
      callback(new Error('请输入最多包含2位小数的正数!'));
    } else {
      callback();
    }
  };
  const INTEGER = (rule, value, cb) => {
    if (!(/^[1-9]{1,}[\d]*$/.test(value))) {
      cb(new Error('请输入正整数!'));
    } else {
      cb();
    }
  };
//   const REQUIRED = ({ message = '不能为空', trigger = 'blur' } = {}) => {
//     return { required: true, message: message, trigger: trigger };
//   };
  const MAX = ({ type = 'string', message = '不能多于1位', trigger = 'blur', max = 1 } = {}) => {
    return { type: type, max: max, message: message, trigger: trigger };
  };
  const MIN = ({ type = 'string', message = '不能少于1位', trigger = 'blur', min = 1 } = {}) => {
    return { type: type, min: min, message: message, trigger: trigger };
  };
const required = (message = '请输入') => {
    return {
        required: true,
        message,
        trigger: 'onChange'
    };
};
  export default {
    idCard: VALIDATE_ID_CARD,
    phone: PHONE,
    standerPhone,
    required,
    max: MAX,
    min: MIN,
    email: EMAIL,
    pointLimit: POINT_LIMIT,
    cargoSection: CARGO_SECTION,
    curDateLimit: CUR_DATE_LIMIT,
    stringNumber: STRING_NUMBER,
    integer: INTEGER,
    validUserName,
    isExternal
  };
