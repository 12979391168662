<template>
  <VanDialog
    :value="value"
    show-cancel-button
    :close-on-click-overlay="true"
    @click-overlay="close"
    :before-close="submit"
    @cancel="close"
    title="注册"
  >
    <div class="pt20 plr20 pb20 ">
      <van-field label="邮箱:" v-model.trim="form.account" placeholder="请输入邮箱" />
      <van-field label="新密码:" type="password" v-model="form.password" placeholder="请输入密码" />
      <van-field label="确认密码:" type="password" t v-model="form.newPwd" placeholder="请再次确认密码" />
    </div>
  </VanDialog>
</template>
<script>
import apiUser from '@/api/src/apiUser';
import config from '@/config';
import * as auth from '@/utils/auth';
import {emailReg} from '@/utils/src/validateRules';
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        account: '',
        password: '',
        newPwd: ''
      }
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit('input', false);
    },
    async submit(action, done) {
      console.log(auth.loc.get('appInfo'));
      let appInfo = auth.loc.get('appInfo', {});
      if (action === 'confirm') {
        if (!emailReg.test(this.form.account) || !this.form.account) {
          this.$msg.showMsg('请输入正确的邮箱!');
          return done(false);
        }
        if (this.form.password !== this.form.newPwd) {
          this.$msg.showMsg('两次密码不一样');
          return done(false);
        }
        if (!this.form.password) {
          this.$msg.showMsgFail('密码不能为空!');
          return;
        }
        apiUser
          .register({ ...this.form, imei: auth.getImei(), appInfoId: appInfo.id })
          // .register({ ...this.form })
          .then(res => {
            this.$emit('after', res);
            this.close();
            done();
          })
          .catch(_ => {
            done(false);
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.input-box {
  border: 1px solid #eee;
  border-radius: 0.1rem;
}
</style>
